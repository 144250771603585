import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import get from "lodash.get"
import AuthUserContext from "./context"
import Loading from "../../components/atoms/Loading"
import { withFirebase } from "../../utils/Firebase"
import * as ROUTES from "../../utils/Constants/Routes"

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      const { firebase, history } = this.props
      this.listener = firebase.onAuthUserListener(
        async (authUser) => {
          if (get(authUser, ["roles", "BUSINESS_ADMIN"])) {
            const user = await firebase.getOrgAgentByEmail(
              authUser.organizationId,
              authUser.email
            )

            const { acceptedTermsAndConds, acceptedPrivacyPolicies } = user

            if (
              !acceptedTermsAndConds ||
              acceptedTermsAndConds === undefined ||
              !acceptedPrivacyPolicies ||
              acceptedPrivacyPolicies === undefined
            ) {
              history.push(ROUTES.TERMS_CONDS)
            }
          }

          if (!condition(authUser)) {
            localStorage.removeItem("authUser")
            history.push(ROUTES.SIGN_IN)
          }
        },
        () => history.push(ROUTES.SIGN_IN)
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            condition(authUser) ? <Component {...this.props} /> : <Loading />
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  WithAuthorization.propTypes = {
    firebase: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
  }

  return compose(withRouter, withFirebase)(WithAuthorization)
}

export default withAuthorization
