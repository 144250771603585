/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { FilterList } from "@styled-icons/material/FilterList"
import { Add } from "@styled-icons/material/Add"
import SearchInput from "../SearchInput"
import ActionButton from "../ActionButton"
import CustomCheckbox from "../CustomCheckbox"

const Nav = ({
  titleKey,
  descriptionKey,
  onFilter,
  onSearchChange,
  searchValue,
  showFilterOptions,
  filterOptions: FilterOptionsComponent,
  onAddClick,
  addText,
  isFilterActive,
  showInactive,
  onShowInactiveChange,
}) => {
  const { t } = useTranslation()
  const [showInactiveRecords, setShowInactiveRecords] = useState(false)

  return (
    <NavWrapper>
      <PageInformation>
        {titleKey && <Title>{t(titleKey)}</Title>}
        {descriptionKey && <Description>{t(descriptionKey)}</Description>}
      </PageInformation>
      <ActionsWrapper>
        {showInactive && (
          <div style={{ width: "200px" }} key={`${t(addText)}`}>
            <label style={{ display: "flex", cursor: "pointer" }}>
              <span style={{ minWidth: 120, marginRight: 10 }}>
                {t("show_inactive")}
              </span>
              <CustomCheckbox
                checked={showInactiveRecords}
                onChange={() => {
                  setShowInactiveRecords(!showInactiveRecords)
                  onShowInactiveChange(!showInactiveRecords)
                }}
              />
            </label>
          </div>
        )}
        {onSearchChange && (
          <SearchInput value={searchValue} onChange={onSearchChange} />
        )}
        {onFilter && (
          <div style={{ position: "relative" }}>
            <ActionButton onActionClick={onFilter}>
              <FilterContainer isActive={isFilterActive}>
                <FilterList size={18} />
                <FilterText>Filter</FilterText>
              </FilterContainer>
            </ActionButton>
            <FilterOptions open={showFilterOptions}>
              <FilterOptionsComponent />
            </FilterOptions>
          </div>
        )}
        {/* {get(currentUser, ["userType"]) === "admin" &&
          get(currentUser, ["roles", "SUPER_ADMIN"])( */}
        {onAddClick && (
          <ActionButton onActionClick={onAddClick} dark>
            <FilterContainer>
              <Add size={18} />
              <span>{t(addText)}</span>
            </FilterContainer>
          </ActionButton>
        )}
      </ActionsWrapper>
    </NavWrapper>
  )
}

const FilterOptions = styled.div`
  position: absolute;
  width: ${(props) => (props.open ? "250px" : 0)};
  height: ${(props) => (props.open ? "150px" : 0)};
  right: 5px;
  top: 60px;
  background: white;
  transition: all 300ms;
  overflow-y: scroll;
  border-radius: 5px;
  box-shadow: ${(props) => (props.open ? "0px 1px 5px 4px #A0A0A0" : "none")};
  padding: ${(props) => (props.open ? "10px" : 0)};
  opacity: ${(props) => (props.open ? 1 : 0)};
  z-index: 1000;
`

const NavWrapper = styled.div`
  height: 82px;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: ${(props) => `1px solid ${props.theme.color.semiBorder}`};
`

const PageInformation = styled.div`
  width: 450px;
  display: inherit;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ActionsWrapper = styled.div`
  width: 100%;
  display: inherit;
  align-items: center;
  justify-content: flex-end;
`

const Title = styled.span`
  font-size: 1.875rem;
  line-height: 2.3719rem;
  text-align: left;
  width: 100%;
`

const Description = styled.span`
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: left;
`

const FilterContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.isActive ? `${props.theme.color.primary} !important` : "inherit"};
  font-weight: ${(props) => (props.isActive ? 800 : "inherit")};
`

const FilterText = styled.span``

Nav.defaultProps = {
  onSearchChange: null,
  onFilter: null,
  searchValue: "",
  showFilterOptions: false,
  filterOptions: null,
  onAddClick: null,
  addText: "",
  isFilterActive: false,
  descriptionKey: "",
  showInactive: false,
  onShowInactiveChange: null,
}

Nav.propTypes = {
  titleKey: PropTypes.string.isRequired,
  descriptionKey: PropTypes.string,
  onSearchChange: PropTypes.func,
  onFilter: PropTypes.func,
  searchValue: PropTypes.string,
  showFilterOptions: PropTypes.bool,
  filterOptions: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onAddClick: PropTypes.func,
  addText: PropTypes.string,
  isFilterActive: PropTypes.bool,
  showInactive: PropTypes.bool,
  onShowInactiveChange: PropTypes.func,
}

export default Nav
