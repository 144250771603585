export const DASHBOARD = "/dashboard"
export const SIGN_IN = "/signin"
export const ORGANIZATIONS = "/organizations"
export const ORGS_DETAIL = "/organization/:id"
export const PAYMENTS = "/payments"
export const PAYOUT_DETAIL = "/payout/:id"
export const PAYMENT_DETAIL = "/payment/:id"
export const PAYMENT_DETAIL_OTHERS = "/payment-others/:id"
export const PRODUCT_DETAIL = "/product/:id"
export const ADD_PRODUCT = "/new-product"
export const USERS = "/users"
export const USERS_DETAIL = "/users/:id"
export const USERS_FORMS = "/users/:id/forms"
export const ADMIN = "/admin"
export const SEARCH = "/search"
export const NOTIFICATIONS = "/notifications"
export const HOME = "/"
export const AGENTS_DETAIL = "/agents/:id"
export const DOCTORS_DETAIL = "/doctors/:id"
export const LOCATIONS_DETAIL = "/locations/:id"
export const SESSIONS = "/sessions"
export const SESSIONS_DETAIL = "/sessions/:id"
export const FORGOT_PASSWORD = "/forgot-password"
export const TERMS_CONDS = "/terms-conds"
