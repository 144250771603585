import React, { useState, useEffect } from "react"
import { useToasts } from "react-toast-notifications"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import styled from "styled-components"
import get from "lodash.get"

// Icons
// import { TrashAlt } from "@styled-icons/fa-solid/TrashAlt"
import { Security } from "@styled-icons/material/Security"
import moment from "moment"
import { StarRate } from "@styled-icons/material/StarRate"
import { Refresh } from "@styled-icons/material/Refresh"
import { Block } from "@styled-icons/material/Block"
import { UserCheck } from "@styled-icons/fa-solid/UserCheck"

// Components
import ImageWithText from "../ImageWithText"
import CustomModal from "../../atoms/CustomModal"
import Spinner from "../../atoms/Spinner"

// Utils
import { withFirebase } from "../../../utils/Firebase"

const organizationRoles = [
  { value: "business_partner", label: "Administrador" },
  { value: "client", label: "Empleado" },
]

const UserCard = ({
  user,
  toActivate,
  firebase,
  isAgent,
  isDoctor,
  isLocation,
  orgData,
  deleteUser,
  isRemovingUser,
  isFromOrgView,
  setUserEmail,
  setOpenDrawerRoles,
  locationList,
  location,
  department,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const [medicForm, setMedicForm] = useState(null)
  const [resetting, setResetting] = useState(false)
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenBlockModal, setOpenBlockModal] = useState(false)
  const [currentDoctor, setCurrentDoctor] = useState({})
  const [locationDescription, setLocationDescription] = useState(null)

  const handleClick = () => {
    if (isDoctor) {
      history.push(`/doctors/${user.id}`, {
        currentUser: user,
        orgId: orgData?.uid,
      })
    } else if (isLocation) {
      history.push(`/locations/${user.id}`, {
        currentUser: user,
        orgId: orgData?.uid,
      })
    } else if (!isAgent) {
      history.push(`/users/${user.uid}`, {
        currentUser: user,
      })
    } else {
      history.push(`/agents/${user.id}`, {
        currentUser: user,
        orgId: orgData?.uid,
        locationList,
        location,
        department,
      })
    }
  }

  const getFormatDate = (timestampDate) => {
    const nextPaymentDateFormatted = moment(timestampDate).format("DD-MM-YYYY")

    return nextPaymentDateFormatted
  }

  useEffect(() => {
    if (isRemovingUser && removeModalOpen) {
      setRemoveModalOpen(false)
      setIsLoading(false)
    }
  }, [isRemovingUser, removeModalOpen])

  useEffect(() => {
    if (firebase && user.email && user.userType === "provider") {
      const getForm = async () => {
        const form = await firebase.getUserForm(user.email, "academic")
        setMedicForm(form.data())
      }
      getForm()
    }
  }, [firebase, user])

  useEffect(() => {
    if (isDoctor) {
      const getDoctor = async () => {
        const profile = await firebase.getOrgDoctor(orgData?.uid, user.id)

        setCurrentDoctor({
          ...profile.data(),
        })
      }
      if (firebase && Object.keys(orgData).length > 0) {
        getDoctor()
      }
    }
  }, [firebase, user, orgData])

  useEffect(() => {
    if (firebase && user.location) {
      const getData = async () => {
        let response = ""
        if (user.location) {
          const l = locationList.filter((r) => r.value === user.location)

          const d = await firebase.getOrgDepartmentById(
            orgData?.uid,
            user.location,
            user.department
          )
          const loc = l.length > 0 ? l[0].label : ""
          const dep = d ? d.displayName : ""
          response = `${loc}${dep ? " - ".concat(dep) : ""}`

          setLocationDescription(response)
        }
      }
      getData()
    }
  }, [firebase, user])

  // const openRemoveModal = (e) => {
  //   e.stopPropagation()
  //   setRemoveModalOpen(true)
  // }

  const openBlockModal = async (e) => {
    e.stopPropagation()
    setOpenBlockModal(true)
  }

  const openRolesDrawer = (e) => {
    e.stopPropagation()
    setUserEmail(user.userEmail)
    setOpenDrawerRoles(true)
  }

  const blockAgent = async () => {
    if (user.role === "doctor") {
      await firebase.updateOrgDoctor({
        orgId: orgData.uid,
        id: user.id,
        doctorData: {
          active: !user.active,
        },
      })
    } else {
      await firebase.saveAgentData({
        orgId: orgData.uid,
        id: user.id,
        data: {
          active: !user.active,
        },
      })
    }
    setIsLoading(false)
    setOpenBlockModal(false)
    window.location.reload()
  }

  const rating = Number.isNaN(get(user, ["rating"], 0))
    ? 0
    : get(user, ["rating"], 0)

  const getRoleDescription = (usr) => {
    const r = organizationRoles.filter(
      (p) => p.value === get(usr, "role", undefined)
    )

    return r.length > 0 ? r[0].label : ""
  }

  return (
    <CardContainer
      onClick={!removeModalOpen && !isOpenBlockModal && handleClick}
      toActivate={toActivate || !user.active}
    >
      {toActivate && (
        <Badge>
          <span>
            <Security size="18" />
            {t("needs_verification")}
          </span>
        </Badge>
      )}
      {!isAgent && !isLocation && (
        <ImagesContainer>
          <ImageWithText src={get(user, "photoURL")} />
        </ImagesContainer>
      )}
      {user.userType !== "client" && isFromOrgView && !isLocation && (
        <BlockContainer
          title={
            user?.active ? t("block_user_tooltip") : t("unblock_user_tooltip")
          }
          onClick={(e) => openBlockModal(e)}
        >
          {user?.active ? <Block size="24" /> : <UserCheck size="24px" />}
        </BlockContainer>
      )}
      {/* {user.userType !== "client" && (
        <TrashContainer
          title={t("delete_user_tooltip")}
          onClick={(e) => openRemoveModal(e)}
        >
          <TrashAlt size="24" />
        </TrashContainer>
      )} */}
      {user.userType !== "client" &&
        user.role !== "doctor" &&
        orgData?.type !== "business" && (
          <RolesContainer
            title={t("assign_roles_tooltip")}
            onClick={(e) => openRolesDrawer(e)}
          >
            <Security size="24" />
          </RolesContainer>
        )}
      {!isLocation && (
        <ResetPassword
          title={t("reset_password_tooltip")}
          resetting={resetting}
          onClick={async (e) => {
            setResetting(true)
            e.stopPropagation()
            try {
              await firebase.doPasswordReset(user.userEmail)
              setResetting(false)
              addToast(t("email_reset_send"), {
                appearance: "success",
                placement: "top-right",
              })
            } catch (error) {
              setResetting(false)
              addToast(t("unexpected_error"), {
                appearance: "error",
                placement: "top-right",
              })
            }
          }}
        >
          {/* <span>Resetear contraseña</span> */}
          <Refresh size="24" />
        </ResetPassword>
      )}

      {removeModalOpen && (
        <CustomModal
          key="deleteConfirmationModal"
          modalOpen={removeModalOpen}
          setModalOpen={setRemoveModalOpen}
          showClose={false}
        >
          <ModalContainer>
            <Title>{t("delete_modal_title")}</Title>
            <RegularFont>{t("delete_modal_paragraph")}</RegularFont>
            <RowContainer>
              {isLoading && <Spinner isLoading />}
              {!isLoading && (
                <>
                  <ActionButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setRemoveModalOpen(false)
                    }}
                  >
                    <p>{t("exit")}</p>
                  </ActionButton>
                  <BlockAgentButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsLoading(true)
                      deleteUser(user)
                    }}
                    disabled={isRemovingUser}
                  >
                    <p>{t("delete_button")}</p>
                  </BlockAgentButton>
                </>
              )}
            </RowContainer>
          </ModalContainer>
        </CustomModal>
      )}
      {isOpenBlockModal && (
        <CustomModal
          key="blockAgentModal"
          modalOpen={isOpenBlockModal}
          setModalOpen={setOpenBlockModal}
          showClose={false}
        >
          <ModalContainer>
            <Title>{t("delete_modal_title")}</Title>
            <RegularFont>
              {user?.active ? t("block_agent_text") : t("unblock_agent_text")}
            </RegularFont>
            <RowContainer>
              {isLoading && <Spinner isLoading />}
              {!isLoading && (
                <>
                  <ActionButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setOpenBlockModal(false)
                    }}
                  >
                    <p>{t("exit")}</p>
                  </ActionButton>
                  <BlockAgentButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsLoading(true)
                      blockAgent()
                    }}
                  >
                    <p>
                      {user?.active ? t("block_agent") : t("unblock_agent")}
                    </p>
                  </BlockAgentButton>
                </>
              )}
            </RowContainer>
          </ModalContainer>
        </CustomModal>
      )}
      <Info toActivate={toActivate}>
        <Label topPosition="0">
          <span>{t("information")}</span>
        </Label>
        <PersonalData>
          <p>{get(user, "displayName", "Name")}</p>
          {!isLocation && <p>{get(user, "email", "Email")}</p>}
          {!isLocation && <p>{getRoleDescription(user)}</p>}
          {!isLocation && <p>{locationDescription}</p>}
          {user.role === "doctor" && orgData?.type === "business" && (
            <p>
              {t("orgs_nextPaymentDate")}:{" "}
              {getFormatDate(get(currentDoctor, "nextPaymentDate"))}
            </p>
          )}
          {!isLocation && (
            <span>
              <StarRate size="12" /> {rating}
            </span>
          )}
        </PersonalData>
        {(medicForm || user.profession) && user.userType === "provider" && (
          <>
            <Label topPosition="110px">
              <span>{t("medic_information")}</span>
            </Label>
            <MedicalData>
              <p>
                {user?.professionLangLabel
                  ? get(user, "professionLangLabel", "N/A")
                  : get(user, "profession", "N/A")}
              </p>
              <p>{get(medicForm, "license", "N/A")}</p>
            </MedicalData>
          </>
        )}
      </Info>
    </CardContainer>
  )
}

UserCard.defaultProps = {
  isFromOrgView: false,
  toActivate: false,
  isAgent: false,
  isDoctor: false,
  isLocation: false,
  isRemovingUser: false,
  deleteUser: () => {},
  locationList: [],
  location: {},
  department: "",
}

UserCard.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string,
    active: PropTypes.bool,
    status: PropTypes.string,
    email: PropTypes.string,
    userEmail: PropTypes.string,
    rating: PropTypes.number,
    profession: PropTypes.string,
    professionLangLabel: PropTypes.string,
    userType: PropTypes.string,
    uid: PropTypes.string,
    id: PropTypes.string,
    role: PropTypes.string,
    location: PropTypes.string,
    department: PropTypes.string,
  }).isRequired,
  toActivate: PropTypes.bool,
  firebase: PropTypes.shape().isRequired,
  isAgent: PropTypes.bool,
  isDoctor: PropTypes.bool,
  isLocation: PropTypes.bool,
  orgData: PropTypes.shape().isRequired,
  deleteUser: PropTypes.func,
  isRemovingUser: PropTypes.bool,
  isFromOrgView: PropTypes.bool,
  setUserEmail: PropTypes.func.isRequired,
  setOpenDrawerRoles: PropTypes.func.isRequired,
  locationList: PropTypes.arrayOf(PropTypes.shape()),
  location: PropTypes.string,
  department: PropTypes.string,
}

const ResetPassword = styled.div`
  height: 56px;
  width: 40px;
  text-align: center;
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  top: 20px;
  right: 120px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  color: ${(props) => props.theme.color.onSurface};
  &:hover {
    transform: scale(1.1);
  }
  & > svg {
    animation: ${(props) =>
      props.resetting ? "rotate 2s linear infinite" : "none"};
    color: ${(props) =>
      props.resetting
        ? props.theme.color.primary
        : props.theme.color.onSurface};
  }
`

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  & > div {
    margin: 0 15px;
  }
`

const CardContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  background: ${(props) =>
    props.toActivate ? "rgba(77, 102, 245, 0.1)" : "white"};
`

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 12px;
  position: absolute;
  min-width: 120px;
  width: auto;
  height: 32px;
  background: #ffffff;
  border-radius: 100px;
  left: -5px;
  top: 25px;
  padding: 0 3px;
  & > span {
    display: flex;
    justify-content: center;
    & > svg {
      margin-right: 6px;
      color: red;
    }
  }
`

const Info = styled.div`
  position: relative;
  display: inherit;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  margin-top: ${(props) => (props.toActivate ? "20px" : "15px")};
`

const Label = styled.div`
  position: absolute;
  top: ${(props) => props.topPosition};
  left: 0;
  & > span {
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 1.5px;
    text-align: left;
    color: ${(props) => props.theme.color.black["300"]};
    text-transform: uppercase;
  }
`

const PersonalData = styled.div`
  position: absolute;
  top: 18px;
  left: 0;
  & > p {
    text-align: left;
    margin: 8px 0;
    &:first-of-type {
      font-size: 1.25rem;
    }
  }

  & > span {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

const MedicalData = styled.div`
  position: absolute;
  top: 130px;
  left: 0;
  & > p {
    text-align: left;
    margin: 5px 0;
  }

  & > span {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.theme.color.white.normal};
  width: 500px;
  height: 260px;
  padding: 22px;
`

// const TrashContainer = styled.div`
//   position: absolute;
//   height: 56px;
//   width: 40px;
//   cursor: pointer;
//   right: 0;
//   top: 20px;
//   text-align: center;
//   &:hover {
//     transform: scale(1, 1.1);
//   }
// `

const BlockContainer = styled.div`
  position: absolute;
  height: 56px;
  width: 40px;
  cursor: pointer;
  right: 40px;
  top: 20px;
  text-align: center;
  &:hover {
    transform: scale(1, 1.1);
  }
`

const RolesContainer = styled.div`
  position: absolute;
  height: 56px;
  width: 40px;
  cursor: pointer;
  right: 80px;
  top: 20px;
  text-align: center;
  &:hover {
    transform: scale(1, 1.1);
  }
`

export const RegularFont = styled.p`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.normal};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
`

export const Title = styled.h5`
  margin: 0;
  ${(props) => props.theme.font_size.large};
  color: ${(props) => props.theme.color.black.normal};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
`

const RowContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 260px;
  margin-top: 25px;
  & > p {
    font-weight: bold;
  }
  & > p:hover {
    color: ${(props) => props.theme.color.lightPurple};
    cursor: pointer;
  }
`

const ActionButton = styled.button`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.medium};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  & > p:hover {
    color: ${(props) => props.theme.color.secondary};
    cursor: pointer;
  }
`

const BlockAgentButton = styled.button`
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.medium};
  font-family: ${(props) => props.theme.font.primary};
  font-weight: 400;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  & > p:hover {
    color: red;
    cursor: pointer;
  }
`

export default withFirebase(UserCard)
