import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { ExitToApp } from "@styled-icons/material/ExitToApp"

import { withFirebase } from "../../../utils/Firebase"

const SignOutButton = ({ firebase }) => {
  const history = useHistory()

  return (
    <button
      type="button"
      onClick={() => {
        firebase.doSignOut()
        history.push("/")
      }}
      style={{
        marginBottom: 20,
      }}
    >
      <ExitToApp size={24} />
    </button>
  )
}

SignOutButton.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(SignOutButton)
// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial
