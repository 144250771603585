import app from "firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/firestore"
import "firebase/storage"
import moment from "moment"
import isNill from "lodash.isnil"
import get from "lodash.get"
import config from "./config"
import { countries } from "../App"

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.database()
    this.firestore = app.firestore()
    this.storage = app.storage()
  }

  async getIdToken() {
    return this.auth.currentUser.getIdToken()
  }

  // *** Auth API ***
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  async doPasswordReset(email) {
    return this.auth.sendPasswordResetEmail(email)
  }

  async reset({ email }) {
    return this.auth.sendPasswordResetEmail(email.toLowerCase())
  }

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password)

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.email)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data()
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {}
            }

            // merge auth and db user
            const newAuthUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            }
            next(newAuthUser)
          })
      } else {
        fallback()
      }
    })

  // *** User API ***

  user = (email) => this.firestore.collection("users").doc(email)

  getUsersByType = async (type, country) => {
    let users = null
    const validCountries = countries
      .filter((c) => c.valid)
      .map((co) => co.value)
    if (country) {
      users = await this.firestore
        .collection("users")
        .where("userType", "==", type)
        .where("countryBusiness", "==", country)
        .get()
      return users
    }
    if (validCountries.length >= 10) {
      const localUsers = { docs: [] }
      const userPromises = validCountries.map((c) => {
        return this.firestore
          .collection("users")
          .where("userType", "==", type)
          .where("country", "==", c)
          .get()
      })
      const data = await Promise.all(userPromises)
      data.forEach(async (d) => {
        if (d.docs.length > 0) {
          const { docs } = d
          localUsers.docs.push(...docs)
        }
      })
      return localUsers
    }

    users = await this.firestore
      .collection("users")
      .where("userType", "==", type)
      .where("country", "!=", null)
      .where("country", "in", validCountries)
      .get()
    return users
  }

  getNoCountryUsers = async (type) => {
    const validCountries = countries
      .filter((c) => c.valid)
      .map((co) => co.value)
    try {
      // const usersT = { docs: [] }
      const usersT = await this.firestore
        .collection("users")
        .where("userType", "==", type)
        .where("country", "not-in", [...validCountries, null])
        .get()

      const nullCountryUsers = await this.firestore
        .collection("users")
        .where("userType", "==", type)
        .where("country", "==", null)
        .get()

      let result = null
      if (
        nullCountryUsers &&
        usersT &&
        nullCountryUsers.docs.length > 0 &&
        usersT.docs.length > 0
      ) {
        result = [...usersT.docs, ...nullCountryUsers.docs]
      } else if (nullCountryUsers && nullCountryUsers.docs.length > 0) {
        result = nullCountryUsers.docs
      } else if (usersT && usersT.docs.length > 0) {
        result = usersT.docs
      }
      return result && result.length > 0
        ? result.map((d) => {
            return { id: d.id, ...d.data(), country: "Others" }
          })
        : true
    } catch (error) {
      return []
    }
  }

  getUserForm = async (email, form) =>
    this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc(form)
      .get()

  users = () => this.firestore.collection("users")

  chats = () => this.firestore.ref("chats")

  saveUserData = async ({ email, data }) => {
    const newUserRef = this.firestore.doc(`users/${email}`)
    return newUserRef.set(
      {
        ...data,
        indexDocument: true,
      },
      { merge: true }
    )
  }

  saveAgentData = async ({ orgId, id, data }) => {
    const newAgentRef = this.firestore.doc(
      `organizations/${orgId}/agents/${id}`
    )
    return newAgentRef.set(data, { merge: true })
  }

  saveNewOrg = async ({ data }) => {
    const newOrgRef = this.firestore.collection("organizations").doc()
    await newOrgRef.set({
      ...data,
    })
    return newOrgRef.id
  }

  org = (id) => this.firestore.collection("organizations").doc(id)

  getOrgsByType = async (type, id, country) => {
    if (id) {
      return this.firestore.collection("organizations").doc(id).get()
    }
    if (country) {
      return this.firestore
        .collection("organizations")
        .where("type", "==", type)
        .where("country", "==", country)
        .get()
    }
    return this.firestore
      .collection("organizations")
      .where("type", "==", type)
      .get()
  }

  getOrgs = async (id, country) => {
    if (id) {
      return this.firestore.collection("organizations").doc(id).get()
    }
    if (country) {
      return this.firestore
        .collection("organizations")
        .where("country", "==", country)
        .get()
    }
    return this.firestore.collection("organizations").get()
  }

  getOrgForm = async (id, type) => {
    if (type === "agents") {
      return this.firestore
        .collection("organizations")
        .doc(id)
        .collection(type)
        .orderBy("active", "desc")
        .orderBy("role")
        .orderBy("displayName")
        .get()
    }
    return this.firestore
      .collection("organizations")
      .doc(id)
      .collection(type)
      .get()
  }

  addOrgProduct = async ({ orgId, productData }) => {
    const newProductRef = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("products")
    return newProductRef.add({
      ...productData,
    })
  }

  addOrgProducts = async ({ orgId, products }) => {
    const getDuplicatedProduct = (newProduct, indexedProduct) =>
      indexedProduct.sku === newProduct.sku &&
      indexedProduct.cie11 === newProduct.cie11 &&
      indexedProduct.pharmaceuticalPresentation ===
        newProduct.pharmaceuticalPresentation

    const orgProducts = await this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("products")
      .get()

    const productsToUpdate = []
    const productsToCreate = []

    const parsedDbProducts = orgProducts.docs.map((doc) => ({
      id: doc.id,
      ref: doc.ref,
      ...doc.data(),
    }))

    products.forEach((localProduct) => {
      const isDuplicatedProduct = parsedDbProducts.find((prod) =>
        getDuplicatedProduct(localProduct, prod)
      )

      if (!isNill(isDuplicatedProduct)) {
        productsToUpdate.push({
          ref: isDuplicatedProduct.ref,
          data: localProduct,
        })
      } else {
        productsToCreate.push(localProduct)
      }
    })

    const newProductOperations = productsToCreate.map((prod) =>
      this.addOrgProduct({ orgId, productData: prod })
    )

    const updateProductOperations = productsToUpdate.map((prod) =>
      prod.ref.update(prod.data)
    )

    return Promise.all([newProductOperations, updateProductOperations])
  }

  updateOrgProduct = async ({ orgId, documentId, productData }) => {
    const existingProduct = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("products")
      .doc(documentId)
    return existingProduct.update(productData)
  }

  deleteOrgProduct = async ({ orgId, documentId }) => {
    const existingProduct = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("products")
      .doc(documentId)
    return existingProduct.delete()
  }

  updateOrgAgent = async ({ orgId, id, agentData }) => {
    const existingAgent = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("agents")
      .doc(id)
    return existingAgent.update(agentData)
  }

  updateOrgData = async ({ orgId, orgData }) => {
    const existingOrg = this.firestore.collection("organizations").doc(orgId)
    return existingOrg.set(
      {
        ...orgData,
      },
      {
        merge: true,
      }
    )
  }

  getOrgAgent = async (orgId, agent) =>
    this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("agents")
      .doc(agent)
      .get()

  getOrgAgentByEmail = async (orgId, email) => {
    let result = null
    const agents = await this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("agents")
      .where("email", "==", email)
      .get()

    if (agents && agents.docs.length > 0) {
      if (agents && agents.docs.length > 0) {
        agents.docs.forEach((agent) => {
          result = { id: agent.id, ...agent.data() }
        })
      }
    }

    return result
  }

  addOrgColaborator = async ({ orgId, agentData }) => {
    const newUserRef = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("agents")

    // const newUserRef = this.firestore.doc(
    //   `organizations/${orgId}/agents/${email}`
    // )
    // return newAddressRef.add({
    return newUserRef.add(agentData)
  }

  checkExistingAgent = async (email) => {
    const result = []
    try {
      const orgs = await this.firestore.collection("organizations").get()

      if (orgs && orgs.docs.length > 0) {
        for await (const org of orgs.docs) {
          const agents = await this.firestore
            .collection("organizations")
            .doc(org.id)
            .collection("agents")
            .where("email", "==", email)
            .get()

          if (agents && agents.docs.length > 0) {
            agents.docs.forEach((agent) => {
              result.push({
                id: agent.id,
                orgId: org.id,
                businessName: org.data().businessName,
                ...agent.data(),
              })
            })
          }
        }
      }
    } catch (error) {
      console.log("error:", error)
    }
    return result
  }

  addOrgLocation = async ({ orgId, locationData }) => {
    const newUserRef = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("locations")

    return newUserRef.add(locationData)
  }

  addOrgLocationDepartment = async ({ orgId, locationId, departmentData }) => {
    const newUserRef = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("locations")
      .doc(locationId)
      .collection("departments")

    return newUserRef.add(departmentData)
  }

  getOrgLocation = async (orgId, location) =>
    this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("locations")
      .doc(location)
      .get()

  updateOrgLocation = async ({ orgId, id, locationData }) => {
    const existingLocation = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("locations")
      .doc(id)
    return existingLocation.update(locationData)
  }

  getOrgLocationList = async (orgId) => {
    const result = []
    try {
      const locations = await this.firestore
        .collection("organizations")
        .doc(orgId)
        .collection("locations")
        .get()

      if (locations && locations.docs.length > 0) {
        locations.docs.forEach((location) => {
          result.push({
            id: location.id,
            ...location.data(),
          })
        })
      }
    } catch (error) {
      console.log("error:", error)
    }
    return result
  }

  getOrgDepartmentByLocation = async (orgId, locationId) => {
    const result = []
    try {
      const departments = await this.firestore
        .collection("organizations")
        .doc(orgId)
        .collection("locations")
        .doc(locationId)
        .collection("departments")
        .get()

      if (departments && departments.docs.length > 0) {
        departments.docs.forEach((department) => {
          result.push({
            id: department.id,
            ...department.data(),
          })
        })
      }
    } catch (error) {
      console.log("error:", error)
    }
    return result
  }

  getOrgDepartmentById = async (orgId, locationId, departmentId) => {
    let result = {}
    try {
      if (locationId && departmentId) {
        const department = await this.firestore
          .collection("organizations")
          .doc(orgId)
          .collection("locations")
          .doc(locationId)
          .collection("departments")
          .doc(departmentId)
          .get()

        if (department && department.exists) {
          result = {
            id: department.id,
            ...department.data(),
          }
        }
      }
    } catch (error) {
      console.log("error:", error)
    }
    return result
  }

  deleteOrgDepartment = async ({ orgId, locationId, departmentId }) => {
    const existingDepto = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("locations")
      .doc(locationId)
      .collection("departments")
      .doc(departmentId)
    return existingDepto.delete()
  }

  addOrgDoctor = async ({ orgId, doctorData }) => {
    const newUserRef = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("doctors")

    return newUserRef.add(doctorData)
  }

  getOrgDoctor = async (orgId, doctor) =>
    this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("doctors")
      .doc(doctor)
      .get()

  getOrgDoctorList = async (orgId) => {
    const result = []
    try {
      const doctors = await this.firestore
        .collection("organizations")
        .doc(orgId)
        .collection("doctors")
        .get()

      if (doctors && doctors.docs.length > 0) {
        doctors.docs.forEach((doctor) => {
          result.push({
            id: doctor.id,
            ...doctor.data(),
          })
        })
      }
    } catch (error) {
      console.log("error:", error)
    }
    return result
  }

  updateOrgDoctor = async ({ orgId, id, doctorData }) => {
    const existingAgent = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("doctors")
      .doc(id)
    return existingAgent.update(doctorData)
  }

  addDoctorOrganization = async (email, orgId) => {
    const userRef = this.firestore.collection("users").doc(email)
    const orgObj = await this.firestore
      .collection("organizations")
      .doc(orgId)
      .get()
    const { id, businessName } = orgObj.data()

    const collectionName = "organizations"
    const orgData = {}
    orgData[id] = {
      orgId,
      id,
      businessName,
      active: true,
      createdAt: Date.now(),
    }

    return userRef.set(
      {
        [collectionName]: orgData,
      },
      { merge: true }
    )
  }

  getDoctorList = async () => {
    const result = []
    try {
      const users = await this.firestore
        .collection("users")
        .where("userType", "==", "provider")
        .where("active", "==", true)
        .get()

      if (users && users.docs.length > 0) {
        users.docs.forEach((doctor) => {
          result.push({
            id: doctor.id,
            ...doctor.data(),
          })
        })
      }
    } catch (error) {
      console.log("error:", error)
    }
    return result
  }

  updateProvider = async ({ email, providerData }) => {
    const existingAgent = this.firestore.collection("users").doc(email)
    return existingAgent.update(providerData)
  }

  uploadToStorage(name, blob, metadata) {
    return this.storage
      .ref()
      .child(name)
      .put(blob, { customMetadata: metadata })
  }

  getDownloadURLStorage(name) {
    return this.storage.ref().child(name).getDownloadURL()
  }

  async getTransaction(transactionId) {
    return this.firestore.collection("transactions").doc(transactionId).get()
  }

  async getDashboard(email) {
    return this.firestore
      .collection("transactions")
      .where("metadata.providerEmail", "==", email)
      .get()
  }

  async getMonthDashboard() {
    return this.firestore
      .collection("transactions")
      .where("createdAt", ">=", moment().startOf("month").valueOf())
      .where("createdAt", "<=", moment().endOf("month").valueOf())
      .get()
  }

  async getFilteredDashboardData(dates) {
    return this.firestore
      .collection("transactions")
      .where("createdAt", ">=", dates.start)
      .where("createdAt", "<=", dates.end)
      .get()
  }

  async getFormOptions(type) {
    return this.firestore.collection("formOptions").doc(type).get()
  }

  async saveExchanges(data, collectionName) {
    const exchangesRef = this.firestore
      .collection("formOptions")
      .doc("exchanges")

    return exchangesRef.set(
      {
        [collectionName]: data,
      },
      { merge: true }
    )
  }

  subscribeToTokens({ orgId, onSnapshot }) {
    const tokensRef = this.firestore.collection("organizations").doc(orgId)
    return tokensRef
      .collection("tokens")
      .orderBy("createdAt", "asc")
      .onSnapshot(onSnapshot)
  }

  getOrgTokens = async ({ orgId }) => {
    const result = []
    const tokensRef = this.firestore.collection("organizations").doc(orgId)

    try {
      const tokens = await tokensRef
        .collection("tokens")
        .orderBy("createdAt", "desc")
        .get()

      if (tokens && tokens.docs.length > 0) {
        tokens.docs.forEach((token) => {
          result.push({
            id: token.id,
            ...token.data(),
          })
        })
      }
    } catch (error) {
      console.log("error:", error)
    }

    return result
  }

  addOrgBillingAddress = async ({ orgId, billingAddress }) => {
    const newAddressRef = this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("addresses")
    return newAddressRef.add({
      ...billingAddress,
    })
  }

  getOrgAddress = async (orgId) => {
    return this.firestore
      .collection("organizations")
      .doc(orgId)
      .collection("addresses")
      .limit(1)
      .get()
  }

  getUserByReferenceCode = (referenceCode) => {
    return this.firestore
      .collection("users")
      .where("referenceCode", "==", referenceCode)
      .get()
  }

  getOrganizationByReferenceCode = (referenceCode) => {
    return this.firestore
      .collection("organizations")
      .where("referenceCode", "==", referenceCode)
      .get()
  }

  getPricing = () => {
    return this.firestore
      .collection("environment")
      .doc("settings/doc/pricing")
      .get()
  }

  updatePricing = (data) => {
    const environmentRef = this.firestore
      .collection("environment")
      .doc("settings/doc/pricing")

    return environmentRef.set(data, { merge: true })
  }

  updateProviderCharges = (data) => {
    const environmentRef = this.firestore
      .collection("environment")
      .doc("settings/doc/pricing")

    return environmentRef.set(data, { merge: true })
  }

  async saveProviderChargesByDoc({ email, data }) {
    return this.firestore
      .collection("users")
      .doc(email)
      .collection("forms")
      .doc("chargesByDoc")
      .set(data, {
        merge: true,
      })
  }

  getPromotions = async () => {
    const result = []
    try {
      const promotions = await this.firestore.collection("promotions").get()

      if (promotions && promotions.docs.length > 0) {
        promotions.docs.forEach((promotion) => {
          result.push({
            id: promotion.id,
            ...promotion.data(),
          })
        })
      }
    } catch (error) {
      console.log("error:", error)
    }

    return result
  }

  savePromotion = async ({ promotionData }) => {
    const newPromotionRef = this.firestore.collection("promotions").doc()
    await newPromotionRef.set({
      ...promotionData,
    })
    return newPromotionRef.id
  }

  updatePromotionData = async ({ promotionId, promotionData }) => {
    const existingPromo = this.firestore
      .collection("promotions")
      .doc(promotionId)
    return existingPromo.set(
      {
        ...promotionData,
      },
      {
        merge: true,
      }
    )
  }

  saveInvoice = async ({ invoiceData }) => {
    const newInvoiceRef = this.firestore.collection("invoices").doc()

    await newInvoiceRef.set({
      ...invoiceData,
    })
    return newInvoiceRef.id
  }

  saveInvoiceItems = async ({ invoiceId, invoiceItems }) => {
    const newInvoiceItemRef = this.firestore
      .collection("invoices")
      .doc(invoiceId)
      .collection("items")

    for await (const i of invoiceItems) {
      await newInvoiceItemRef.add({
        ...i,
      })
    }
  }

  getInvoiceConsecutive = async ({ country }) => {
    const invoiceSettingRef = await this.firestore
      .collection("invoices")
      .doc("setting")
      .get()
    let newConsecutive = null

    if (invoiceSettingRef.exists) {
      const invoiceSetting = invoiceSettingRef.data()
      const countrySetting = get(invoiceSetting, country)
      let consecutive = 0

      if (countrySetting) {
        consecutive = countrySetting.consecutive
      }

      newConsecutive = consecutive + 1
      await this.firestore
        .collection("invoices")
        .doc("setting")
        .set(
          {
            [country]: {
              consecutive: newConsecutive,
            },
          },
          {
            merge: true,
          }
        )
    } else {
      newConsecutive = 1
      await this.firestore
        .collection("invoices")
        .doc("setting")
        .set(
          {
            [country]: {
              consecutive: newConsecutive,
            },
          },
          {
            merge: true,
          }
        )
    }

    return newConsecutive
  }

  getInvoiceItemsById = (id) => {
    return this.firestore
      .collection("invoices")
      .doc(id)
      .collection("items")
      .get()
  }

  getInvoicesByOrg = async (orgId) => {
    const results = []
    const invoices = await this.firestore
      .collection("invoices")
      .where("customerId", "==", orgId)
      .get()
    if (invoices && invoices.docs.length > 0) {
      invoices.docs.forEach((invoice) => {
        results.push({
          id: invoice?.id,
          ...invoice.data(),
        })
      })
    }
    return results
  }

  getMedicalRecords = async (userEmail) =>
    this.firestore
      .collection("users")
      .doc(userEmail)
      .collection("medicalFollowUp")
      .orderBy("createdAt", "desc")
      .get()

  getUserRecord = async (userEmail) =>
    this.firestore.collection("users").doc(userEmail).get()

  version = () => {
    return "1.0.0"
  }
}

export default Firebase
