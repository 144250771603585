import React, { useState, useContext, useEffect } from "react"
import { useToasts } from "react-toast-notifications"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { Formik, Form } from "formik"
import styled from "styled-components"
import { Box, Flex } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import ButtonWithLoader from "../../components/atoms/ButtonWithLoader"
import { withFirebase } from "../../utils/Firebase"
import { AuthUserContext } from "../../context/Session"

const TermsAndCondsPage = ({ firebase }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { addToast } = useToasts()
  const authUser = useContext(AuthUserContext)
  const [isChecked, setIsChecked] = useState(false)
  const [acceptedTermsAndConds, setAcceptedTermsAndConds] = useState(false)
  const [acceptedPrivacyPolicies, setAcceptedPrivacyPolicies] = useState(false)
  const [ppdPathUrl, setPpdPathUrl] = useState("")
  const [tcPathUrl, setTcPathUrl] = useState("")

  useEffect(() => {
    const getPDFURL = async () => {
      const ppdUrl = await firebase.getDownloadURLStorage(
        process.env.REACT_APP_PRIVACY_POLICY_PATH
      )
      const tcUrl = await firebase.getDownloadURLStorage(
        process.env.REACT_APP_TERMS_CONDS_PATH
      )
      setPpdPathUrl(ppdUrl)
      setTcPathUrl(tcUrl)
    }
    if (firebase) {
      getPDFURL()
    }
  }, [firebase])

  return (
    <TermsAndCondsContainer>
      <Container>
        <HeaderText>{t("title_terms_and_conditions")}</HeaderText>
        <Formik
          initialValues={{
            acceptedTermsAndConds: false,
            acceptedPrivacyPolicies: false,
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true)
            if (authUser) {
              const user = await firebase.getOrgAgentByEmail(
                authUser.organizationId,
                authUser.email
              )

              if (user) {
                firebase
                  .updateOrgAgent({
                    orgId: authUser.organizationId,
                    id: user.id,
                    agentData: {
                      acceptedTermsAndConds: values.acceptedTermsAndConds,
                      acceptedPrivacyPolicies: values.acceptedPrivacyPolicies,
                    },
                  })
                  .then(() => {
                    addToast(t("saved_successfully"), { appearance: "success" })
                    history.push("/")
                  })
                  .catch(() => {
                    addToast(t("unexpected_error"), { appearance: "error" })
                  })
              }
            }

            actions.setSubmitting(false)
          }}
        >
          {(formProps) => (
            <Form>
              <Flex flexDirection="row" alignItems="center" marginLeft="24px">
                <Box marginRight="10px">
                  <input
                    type="checkbox"
                    id="terms"
                    onClick={(e) => {
                      setIsChecked(e.target.checked && acceptedPrivacyPolicies)
                      setAcceptedTermsAndConds(e.target.checked)
                      formProps.setFieldValue(
                        "acceptedTermsAndConds",
                        e.target.checked
                      )
                    }}
                  />
                </Box>

                <a href={tcPathUrl} target="_blank" rel="noreferrer">
                  {t("title_terms_and_conditions")}
                </a>
              </Flex>

              <Flex flexDirection="row" alignItems="center" marginLeft="24px">
                <Box marginRight="10px">
                  <input
                    type="checkbox"
                    id="pp"
                    onClick={(e) => {
                      setIsChecked(e.target.checked && acceptedTermsAndConds)
                      setAcceptedPrivacyPolicies(e.target.checked)
                      formProps.setFieldValue(
                        "acceptedPrivacyPolicies",
                        e.target.checked
                      )
                    }}
                  />
                </Box>

                <a href={ppdPathUrl} target="_blank" rel="noreferrer">
                  {t("title_privacy_policies")}
                </a>
              </Flex>

              <SubmitButton
                disabled={!isChecked}
                isLoading={formProps.isSubmitting}
                type="submit"
              >
                {t("accept")}
              </SubmitButton>
            </Form>
          )}
        </Formik>
      </Container>
    </TermsAndCondsContainer>
  )
}

const TermsAndCondsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Container = styled.div`
  width: calc(60% - 550px);
  min-width: 440px;
`

export const SubmitButton = styled(ButtonWithLoader)`
  width: 100%;
  margin-top: 12px;
`

export const HeaderText = styled.span`
  font-size: 2.5rem;
  & > svg {
    width: 69px;
    height: 30px;
  }
`

TermsAndCondsPage.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(TermsAndCondsPage)
