/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Nav from "../../atoms/Nav"
import CardsContainer from "../../atoms/CardsContainer"
import UserCard from "../../molecules/UserCard"
import VisitorDrawer from "../../molecules/VisitorDrawer"
import RolesDrawer from "../../molecules/RolesDrawer"
import CustomCheckbox from "../../atoms/CustomCheckbox"

const OrgUsers = ({
  agents,
  orgData,
  onUserCreated,
  orgName,
  deleteUser,
  isRemovingUser,
  country,
  locations,
}) => {
  const [searchValue, setSearchValue] = useState("")
  const [openDrawerCreate, setOpenDrawerCreate] = useState(false)
  const [openDrawerRoles, setOpenDrawerRoles] = useState(false)
  const [openFilters, setOpenFilters] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const [showInactive, setShowInactive] = useState(false)
  const handleSearchChange = (ty) => {
    setSearchValue(ty.target.value)
  }

  const handleShowInactiveChange = (isShowInactive) => {
    setShowInactive(isShowInactive)
  }

  const [locationFilters, setLocationFilters] = useState({})
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [filteredAgentsList, setFilteredAgentsList] = useState(null)

  const handleFilterChange = () => {
    setOpenFilters(!openFilters)
  }

  const [locationList, setLocationList] = useState([])

  useEffect(() => {
    if (locations && locations.length > 0) {
      const result = []
      locations.forEach((loc) => {
        result.push({
          value: loc.id,
          label: loc.data().displayName,
        })
      })

      setLocationList(result)
    }
  }, [locations])

  useEffect(() => {
    if (locationFilters) {
      const keys = Object.keys(locationFilters)
      if (keys.length > 0) {
        const values = keys.filter((k) => locationFilters[k].value)
        if (values.length > 0) {
          setIsFilterActive(true)
          const agent = agents.filter(
            (u) =>
              values.includes(u.data().location) &&
              u.data().active !== showInactive
          )
          setFilteredAgentsList(agent)
        } else {
          setIsFilterActive(false)
          setFilteredAgentsList(
            agents.filter((u) => u.data().active !== showInactive)
          )
        }
      }
    }
  }, [locationFilters, agents, showInactive])

  const handleAddOrganization = () => {
    if (openDrawerCreate) {
      // setLoadingOrgs(true)
      onUserCreated()
    }
    setOpenDrawerCreate(!openDrawerCreate)
  }

  const handleAssignRoles = () => {
    if (openDrawerRoles) {
      setUserEmail("")
    }
    setOpenDrawerRoles(!openDrawerRoles)
  }

  const agentsList =
    filteredAgentsList || agents.filter((u) => u.data().active !== showInactive)

  return (
    <>
      <Nav
        titleKey="orgs_users"
        onSearchChange={handleSearchChange}
        onFilter={handleFilterChange}
        onAddClick={handleAddOrganization}
        searchValue={searchValue}
        addText="create_new"
        showFilterOptions={openFilters}
        showInactive
        onShowInactiveChange={handleShowInactiveChange}
        filterOptions={() => (
          <FilterOptions>
            {locationList.map((c) => (
              <div style={{ width: "100%" }} key={`${c.label}-${c.value}`}>
                <label style={{ display: "flex", cursor: "pointer" }}>
                  <span style={{ minWidth: 150, marginRight: 80 }}>
                    {c.label}
                  </span>
                  <CustomCheckbox
                    checked={
                      locationFilters[c.value] && locationFilters[c.value].value
                    }
                    onChange={() =>
                      setLocationFilters((prev) => ({
                        ...prev,
                        [c.value]: {
                          value: locationFilters[c.value]
                            ? !locationFilters[c.value].value
                            : true,
                        },
                      }))
                    }
                  />
                </label>
                <hr />
              </div>
            ))}
          </FilterOptions>
        )}
        isFilterActive={isFilterActive}
      />
      <Container>
        <CardsContainer
          isEmpty={agents.length === 0}
          emptyMessage="Aun no tienes colaboradores dentro de la organización"
          fullHeight
        >
          {agentsList
            .filter((u) => {
              const data = u.data()
              if (searchValue) {
                return (
                  data.displayName &&
                  data.displayName
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                )
              }
              return true
            })
            .map((usr) => {
              const data = usr.data()
              const userData = {
                ...data,
                id: usr.id,
                email: data.email,
                userEmail: data.email,
              }
              return (
                <UserCard
                  key={usr.id}
                  user={userData}
                  orgData={orgData}
                  deleteUser={deleteUser}
                  isRemovingUser={isRemovingUser}
                  setUserEmail={setUserEmail}
                  setOpenDrawerRoles={setOpenDrawerRoles}
                  isAgent
                  isFromOrgView
                  locationList={locationList}
                  location={locationList.filter(
                    (r) => r.value === userData.location
                  )}
                  department={userData.department}
                />
              )
            })}
        </CardsContainer>
      </Container>
      <VisitorDrawer
        open={openDrawerCreate}
        closeDrawer={handleAddOrganization}
        orgId={orgData.uid}
        orgName={orgName}
        country={country}
      />
      <RolesDrawer
        open={openDrawerRoles}
        closeDrawer={handleAssignRoles}
        userEmail={userEmail}
      />
    </>
  )
}

export const Container = styled.div`
  height: calc(100% - 150px);
`

export const FilterOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`

OrgUsers.defaultProps = {
  agents: [],
  locations: [],
  deleteUser: false,
  isRemovingUser: () => {},
}

OrgUsers.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape()),
  orgData: PropTypes.shape().isRequired,
  orgName: PropTypes.string.isRequired,
  onUserCreated: PropTypes.func.isRequired,
  deleteUser: PropTypes.func,
  isRemovingUser: PropTypes.bool,
  country: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape()),
}

export default OrgUsers
